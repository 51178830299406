.album-container {
  border-top: 5px solid #ceb1b1;
  padding-top: 10px;
}

.album-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: auto;
}

.album-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  width: fit-content;
  padding: 5px;
}
.album-item.selected {
  background-color: aqua;
}

.album-ico {
  font-size: 3rem;
  color: aqua;
}

.album-item.selected > .album-ico {
  color: white;
}
