.mainpage-header {
    box-shadow: 5px 2px #888888;
    width: 100vw;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.upload-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 20px;
}

.upload-section-feature {
    width: 25%;
    border: 1px solid black;
    background: white;
    min-height: 30vh;
    z-index: 2;
    position: fixed;
    left: 0px;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.upload-section-folder {
    width: 100%;
    /* border: 1px solid black; */
    min-height: 35vh;
    border-radius: 10px;
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
}

.upload-section-category {
    width: 25%;
    z-index: 2;
    position: absolute;
    right: 50px;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.upload-section-category .upload-section-category-group {
    border: 1px solid black;
    background: white;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.upload-footer {
    height: 80px;
    width: 80%;
    border: 1px solid black;
    position: relative;
    bottom: 0px;
    left: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.upload-footer > button > span {
    font-size: 20px;
}

.ant-tree-title {
    font-size: 20px;
}

.ant-tree-iconEle {
    font-size: 20px;
    margin-right: 5px;
}

.view-file-modal {
    display: flex;
    flex-direction: column;
}

.video-upload-section {
    width: 100%;
    margin: 10px;
}

.video-list {
    margin: 10px;
}

.video-item-list {
    background: white;
    height: 135px;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    border: 1px solid rgb(173, 172, 172);
}

.react-thumbnail-generator {
    height: 100px;
}

.react-thumbnail-generator > img {
    object-position: 6% 5%;
    object-fit: none;
    width: 100%;
    height: 100%;
}

.file-reviewer {
    width: 500px;
    height: 500px;
    object-fit: contain;
}

.loading {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: black;
    opacity: 0.5;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ZhZivtjbFgycw7x9pM2lu.css-1oin9j2 {
    display: none;
}

._2ONWma4Cs1G8opurFXOGst {
    display: none;
}

.post-img {
    width: 100px;
    min-width: 100px;
    height: 100px;
}

.post-item .ant-card-head {
    border-bottom: none !important;
    padding: 0px !important;
    color: blue;
}

.post-item .ant-card-body {
    padding: 0px !important;
    display: flex;
}

.post-content-review {
    flex: 1;
    padding: 5px;
}

.post-content-edit {
    display: flex;
}

.post-item-container {
    margin: 10px;
    display: flex;
    width: 65vw;
}

.post-item-action {
    display: flex;
    width: 50px;
    justify-content: center;
    padding-top: 20px;
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
}

.post-item {
    padding: 10px;
    flex: 1;
}

.RichTextEditorContainer {
    flex: 1;
}

.video-item-list-desc {
    display: flex;
    flex-direction: column;
}

.upload-section-acc-pane {
    border: 1px solid rgb(184, 184, 184);
    min-height: 100vh;
    width: 29%;
}

.page-intro {
    border: 1px solid rgb(184, 184, 184);
    padding: 10px;
}

@media only screen and (max-width: 600px) {
    .upload-section {
        flex-direction: column;
    }

    .breadcumb-section {
        width: 100vw;
        display: flex;
    }

    .mobile-menu-btn {
        margin-left: auto;
        margin-right: 30px;
        display: block !important;
    }

    .upload-section-acc-pane {
        display: none;
    }

    .ant-drawer-body > .upload-section-acc-pane {
        display: contents;
    }

    .upload-footer > button > span {
        font-size: 1rem;
    }

    .post-content-edit {
        flex-direction: column;
    }

    .RichTextEditorContainer.post-translate-editor {
        margin-top: 10px;
    }
}

.folder-wrapper {
    padding: 1rem;
    border: 1px solid gray;
}

.ant-select-selector {
    border: none !important;
}
