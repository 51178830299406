.video-sub-list{
    padding:10px;
}

.sub-input{
    width: 100%;
    margin: 5px;
    display: flex !important;
}

.sub-view{
    font-size: 2rem;
    background: #00000066;
    color: white;
    text-align: center;
    position: relative;
    width: 100%;
    z-index: 1000;
    transform: translateY(-165%);
    pointer-events: none;
}

.delete-sub-btn {
    width: 35px;
    margin: 0px 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex !important; 
    background: red;
    color: white;
}

.ico-btn{
    width: 35px;
    margin: 0px 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex !important; 
    border: 1px solid black;
}
