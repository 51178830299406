.login-page{
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-page-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px;
    align-items: center;
    justify-content: center
}

.login-section {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.login-section > .ant-input {
    margin:5px;
}

.login-form-item{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.visa-logo {
    width: 70px;
    height: 50px;
}

.payment-section{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border:   1px solid rgb(175, 172, 172);
}