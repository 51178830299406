.acc-pane-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.acc-pane-section {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.acc-pane-info {
  border: 1px solid rgb(150, 150, 150);
  width: 100%;
  text-align: center;
  font-size: 18px;
}
